<template>
  <ul class="btn-toolbar gx-1">
    <slot></slot>
  </ul>
</template>

<script>
export default {

}
</script>
