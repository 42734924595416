<template>
  <ion-page>
    <ion-content :fullscreen="true" class="bg-lighter ion-content">
      <vertical-nav-menu></vertical-nav-menu>
      <div class="nk-wrap">
        <nav-bar></nav-bar>
        <main-block>
          <block-head title="Advisors" :description="'You have total '+pagination.totalRecords+' advisors.'"></block-head>
          <block-content>
            <card no-padding>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header title="">
                    <card-tools v-if="!assignLead">
                      <div class="form-inline flex-nowrap gx-3">
                        <div class="form-wrap w-150px">
                          <select class="form-select form-select-md form-control" v-model="bulkSelectedAction">
                            <option value="">Bulk Action</option>
                            <option value="email">Send Email</option>
                            <option value="suspend">Suspend User</option>
                            <option value="delete">Delete User</option>
                          </select>
                        </div>
                        <div class="btn-wrap">
                          <span class="d-none d-md-block">
                            <nk-button dim outline type="light" v-on:click="bulkAction" :disabled="!bulkSelectedAction.length">Apply</nk-button>
                          </span>
                          <span class="d-md-none">
                            <nk-button dim outline type="light" v-on:click="bulkAction" is-icon-only-button="" :disabled="!bulkSelectedAction.length">
                              <nio-icon icon="ni-arrow-right"></nio-icon>
                            </nk-button>
                          </span>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools v-else>
                      <div class="d-flex align-center">
                        <nk-button type="" is-icon-only-button="" class="btn-trigger" v-on:click="router.push({name: 'adminLeadsList'})">
                          <nio-icon icon="ni-arrow-left"></nio-icon>
                        </nk-button>
                        <h6 class="title ml-2">Select advisor to assign lead</h6>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button=""
                                       class="search-toggle"
                                       v-on:click="searchBarActive=true"
                                       :class="{active: searchBarActive}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="btn-toolbar-sep"></li>
                          <li>
                            <drop-down
                                icon="ni-filter-alt"
                                size="lg"
                                direction="right"
                                :dot="( statusFilter !== '' || mandateFilter !== '' ? 'primary':'')"
                                title="Advisors Filter">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="mandate_filter">Mandate</label>
                                    <select class="form-control form-control-sm" id="mandate_filter" v-model="mandateFilter">
                                      <option value="">All</option>
                                      <option value="1">Signed</option>
                                      <option value="0">Not Signed</option>
                                    </select>
                                  </column>
                                  <column default="12" md="6">
                                    <label class="overline-title overline-title-alt" for="status_filter">Account status</label>
                                    <select class="form-control form-control-sm" id="status_filter" v-model="statusFilter">
                                      <option value="">All</option>
                                      <option value="1">Active</option>
                                      <option value="0">Disabled</option>
                                    </select>
                                  </column>
                                </row>
                              </template>
                              <template v-slot:footer>
                                <nk-button type="dark" dim v-on:click="getAdvisors(true)">Filter</nk-button>
                                <a class="clickable" href="javascript:;" v-on:click="[statusFilter=mandateFilter='',getAdvisors(true)]">Reset Filter</a>
                              </template>
                            </drop-down>
                          </li><!-- li -->
                          <li>
                            <drop-down icon="ni ni-setting" title="" size="xs" direction="right">
                              <ul class="link-check">
                                <li><span>Order</span></li>
                                <li :class="{active: order === 'asc'}"><a href="javascript:;" v-on:click="[order='asc', getAdvisors(true)]">ASC</a></li>
                                <li :class="{active: order === 'desc'}"><a href="javascript:;" v-on:click="[order='desc', getAdvisors(true)]">DESC</a></li>
                              </ul>
                            </drop-down>
                          </li><!-- li -->
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      placeholder="Search advisor"
                      :is-active="searchBarActive"
                      @do-search="searchAdvisors"
                      :search-term="searchFilter"
                      @close-search="[searchBarActive=false, searchAdvisors('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="loadingData">
                  <ion-spinner></ion-spinner>
                </div>
                <template v-else>
                  <div class="card-inner p-0">
                    <special-table>
                      <table-row is-heading>
                        <table-col has-check-box v-if="!assignLead">
                          <b-check-box id="select_all" v-model="selectAll"></b-check-box>
                        </table-col>
                        <table-col>
                          <span class="sub-text">ID</span>
                        </table-col>
                        <table-col>
                          <span class="sub-text">User</span>
                        </table-col>
                        <table-col break-point="mb">City</table-col>
                        <table-col break-point="md">Leads</table-col>
                        <table-col break-point="lg">Joined</table-col>
                        <table-col break-point="lg">Last Login</table-col>
                        <table-col class="nk-tb-col-tools"></table-col>
                      </table-row>

                      <table-row v-for="advisor in advisors" :key="advisor.id">
                        <table-col has-check-box v-if="!assignLead">
                          <b-check-box :id="'user'+advisor.id" v-model="advisor.selected" :key="advisor.id"></b-check-box>
                        </table-col>
                        <table-col>{{ advisor.id }}</table-col>
                        <table-col>
                          <user-small-card :user-name="advisor.name" :user-email="advisor.email" :image="advisor.dp" :initials="advisor.initials"></user-small-card>
                        </table-col>
                        <table-col break-point="mb">
                          <span class="tb-lead">{{ advisor.city }}</span>
                        </table-col>
                        <table-col break-point="md">
                          <ul class="list-status">
                            <li title="Assigned this week"><nio-icon class="text-info" icon="ni-clipboad-check"></nio-icon> <span>{{ advisor.assigned_leads.current_week }}</span></li>
                            <li title="Available this week"><nio-icon icon="ni-clipboard"></nio-icon> <span>{{ advisor.available_leads }}</span></li>
                            <li title="Assigned last week"><nio-icon icon="ni-clipboad-check-fill" class="text-primary"></nio-icon> <span>{{ advisor.assigned_leads.last_week }}</span></li>
                          </ul>
                        </table-col>
                        <table-col break-point="lg">{{ advisor.joined }}</table-col>
                        <table-col break-point="lg">{{ advisor.last_login }}</table-col>
                        <table-col class="nk-tb-col-tools" v-if="!assignLead">
                          <ul class="nk-tb-actions gx-1">
                            <table-action icon="ni-edit-fill"></table-action>
                            <table-action
                                :icon="advisor.user_status === 0 ? 'ni-unlock-fill' : 'ni-lock-fill'" v-on:click="changeAccountStatus([advisor])"></table-action>
                            <table-action icon="ni-dashboard-fill"></table-action>
                            <li>
                              <drop-down-list>
                                <list-item icon="ni-eye" text="View Details" :to="{name: 'advisorProfile', params: {id: advisor.id} }"></list-item>
                                <list-item icon="ni-activity-round" text="Activities"></list-item>
                              </drop-down-list>
                            </li>
                          </ul>
                        </table-col>
                        <table-col v-else>
                          <nk-button type="light" outline is-icon-only-button="">
                            <nio-icon icon="ni-forward-arrow-fill"></nio-icon>
                          </nk-button>
                        </table-col>
                      </table-row>

                    </special-table>

                  </div>
                  <div class="card-inner" v-if="pagination.totalRecords > 10">
                    <pagination
                        v-model="pagination.page"
                        :records="pagination.totalRecords"
                        :per-page="10"
                        @paginate="paginationCallback"
                        theme="bootstrap4"
                        :options="{chunk: 5, texts:{count: '||'}}"/>
                  </div>
                </template>
              </template>
            </card>
          </block-content>
        </main-block>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import {IonContent, IonPage, IonSpinner, alertController, loadingController, toastController} from '@ionic/vue';
import {defineComponent, onMounted, reactive, ref, watch} from 'vue';
import VerticalNavMenu from "@/@core/layouts/vertical-nav-menu/VerticalNavMenu";
import NavBar from "@core/layouts/app-nav-bar/NavBar";
import MainBlock from "@core/layouts/main-block/MainBlock";
import BlockContent from "@core/layouts/main-block/components/BlockContent";
import axios from "@/libs/axios"
import {Card, CardHeader, CardSearch, CardTools} from "@core/components/cards";

import NioIcon from "@core/components/nio-icon/NioIcon";
import NkButton from "@core/components/button/NkButton";

import { BtnToolbar, BtnToolbarToggleWrap } from "@core/components/btn-toolbar"
import "bootstrap"
import DropDown from "@core/components/dropdown/DropDown";

import { Column, Row } from "@core/layouts"

import Pagination from "v-pagination-3"
import BlockHead from "@core/layouts/main-block/components/BlockHead";
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import TableAction from "@core/components/special-table/components/TableAction";
import DropDownList from "@core/components/dropdown-list/DropDownList";
import ListItem from "@core/components/dropdown-list/ListItem";

import {useRoute, useRouter} from "vue-router"

export default defineComponent({
  components: {
    ListItem,
    DropDownList,
    TableAction,
    UserSmallCard,
    BCheckBox,
    SpecialTable,
    TableCol,
    TableRow,
    CardSearch,
    CardTools,
    BlockHead,
    Column,
    Row,
    DropDown,
    BtnToolbar, BtnToolbarToggleWrap,
    NkButton,
    NioIcon,
    CardHeader,
    Card,
    BlockContent,
    MainBlock,
    NavBar,
    VerticalNavMenu,
    IonContent,
    IonPage,
    IonSpinner,
    Pagination,
  },
  setup() {

    const router = useRouter()
    const route  = useRoute()
    let assignLead = ref(false)
    assignLead.value = route.params.action && route.params.action === 'assign_lead'

    let searchFilter  = ref('');
    let statusFilter  = ref('')
    let mandateFilter = ref('')
    let order         = ref('asc')

    let loadingData = ref(false)

    let selectAll    = ref(false)

    let advisors     = ref([])

    let searchBarActive = ref(false)

    let pagination = reactive({
      page: 1,
      totalRecords: 0,
    })
    const paginationCallback = async () => {
      await getAdvisors(true)
    }

    let cancelToken = false
    const getAdvisors = async (reset) => {

      if(false !== cancelToken){
        cancelToken()
        cancelToken = false
      }
      loadingData.value = true
      axios.post('/api/admin/advisors/list', {
            page: pagination.page,
            status: statusFilter.value,
            search: searchFilter.value,
            mandate_filter: mandateFilter.value,
            order: order.value
          },
          {
            cancelToken: new axios.CancelToken( c => {
              cancelToken = c
            })
          })
          .then( (resp) => {
            if(reset){
              advisors.value = []
            }
            for(let x in resp.data.data){
              resp.data.data[x].selected = false;
              advisors.value.push(resp.data.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = resp.data.total_records
            }
          })
          .catch( err => {
            console.log('Invoices.vue ajax error: ', err)
          })
          .then(() => {
            cancelToken = false
            loadingData.value = false
          })
    }

    watch(searchFilter, () => getAdvisors(true))

    const searchAdvisors = (search) => {
      searchFilter.value = search
    }

    onMounted(()=> getAdvisors())

    watch(selectAll, (n) => {
      for(let x in advisors.value){
        advisors.value[x].selected = n
      }
    })

    const changeAccountStatus = async ( advisors, action ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let alertMessage = ''
      let status = ''
      if(advisors.length > 1){
        alertMessage = `Are you sure to ${action} the ${advisors.length} selected advisors?`
        status = (action === 'suspend') ? 0 : 1
      }else{
        alertMessage = `Are you sure to ${(advisors[0].user_status === 0 ? 'activate' : 'suspend')} ${advisors[0].name}'s account?`
        status = (advisors[0].user_status === 0) ? 1 : 0
      }

      let ids = advisors.reduce( (item) => {
        return item.user_id
      })

      let iAlert = await alertController.create({
        message: alertMessage,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/advisors/account_status', {ids: JSON.stringify(ids), status: status})
              .then( resp => {

                toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                toast.color   = resp.data.success ? "primary" : "danger"
                if(resp.data.success){
                  for(let x in advisors){
                    advisors[x].user_status = advisors[x].user_status === 0 ? 1 : 0
                  }
                }
                toast.present()
              })
              .catch(error => {
                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                toast.color = "danger"
                toast.present()
              })
              .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }
    const deleteAdvisors = async ( _advisors ) => {

      const loading = await loadingController.create({message: 'Processing...'})
      const toast   = await toastController.create({position: "top", duration: 3000})

      let ids = []
      for(let x in _advisors){
        ids.push( _advisors[x].user_id)
      }

      let iAlert = await alertController.create({
        message: `Are you sure to delete the ${_advisors.length} selected advisors?`,
        buttons: [
          'Cancel',
          {
            text: 'Confirm', handler: () => {
              loading.present()
              axios.post('/api/admin/advisors/delete', {ids: JSON.stringify(ids)})
              .then( resp => {
                toast.message = resp.data.success ? 'Thank you! action completed' : resp.data.message
                toast.color   = resp.data.success ? "primary" : "danger"
                if(resp.data.success){
                  // Remove the deleted entries from the advisors array
                  for(let x in ids){
                    for(let y in advisors.value){
                      if(advisors.value[y].user_id === ids[x]){
                        advisors.value.splice(y,1)
                        break;
                      }
                    }
                  }
                  // All deleted, no entry left in the advisor array? and previous pages exists
                  if( !advisors.value.length && pagination.page > 1 ){
                    // Deduct from total records value
                    pagination.totalRecords -= ids.length
                    // Goto previous page
                    pagination.page--
                    getAdvisors(true)
                  }
                }
                toast.present()
              })
              .catch(error => {
                toast.message = 'Error: '+error.response.status+' '+error.response.statusText
                toast.color = "danger"
                toast.present()
              })
              .then( () => loading.dismiss())
            }
          }
        ]
      })
      await iAlert.present()
    }

    let bulkSelectedAction = ref('')
    const bulkAction = async () => {
      let selectedAdvisors = []
      for(let x in advisors.value){
        if( advisors.value[x].selected){
          selectedAdvisors.push( advisors.value[x])
        }
      }
      if( selectedAdvisors.length < 1 ){
        return false
      }
      if( bulkSelectedAction.value === 'email' ){
        // TODO
        return false
      }
      else if( bulkSelectedAction.value === 'suspend' ){
        await changeAccountStatus(selectedAdvisors, 'suspend')
      }
      else{
        // Delete
        await deleteAdvisors(selectedAdvisors)
      }
    }

    return {
      advisors,
      getAdvisors,
      loadingData,
      searchAdvisors,

      mandateFilter,
      searchFilter,
      statusFilter,
      order,
      pagination,
      paginationCallback,

      searchBarActive,
      selectAll,

      changeAccountStatus,

      bulkSelectedAction,
      bulkAction,

      assignLead,
      router,
    }
  }
});

</script>
