<template>
  <div class="dropdown">
    <a href="javascript:;" class="btn dropdown-toggle" data-toggle="dropdown" :class="triggerBtnClasses">
      <div class="dot" :class="'dot-'+dot" v-if="dot"></div>
      <nio-icon :icon="icon" v-if="icon"></nio-icon>
      <span v-if="triggerBtnText" class="text-capitalize">{{ triggerBtnText }}</span>
    </a>
    <div class="filter-wg dropdown-menu dropdown-menu-right" :class="dropdownClasses">
      <div class="dropdown-head" v-if="title && title.length">
        <span class="sub-title dropdown-title">{{ title }}</span>
        <slot name="head"></slot>
      </div>
      <div class="dropdown-body" v-if="hasBody" :class="{'dropdown-body-rg' : undefined === noBodyPadding}">
        <slot name="body"></slot>
      </div>
      <div class="dropdown-foot between" v-if="hasFooter">
        <slot name="footer"></slot>
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {ref} from "vue";

export default {
  components: {},
  props: {
    title: {
      required: true,
      type: String,
    },
    icon: {
      type: String,
    },
    size: null,
    dot: {
      type: String,
      default: ''
    },
    direction: null,
    triggerBtnClasses: {
      type: String,
      default: 'btn-trigger btn-icon',
    },
    triggerBtnText: String,
    noBodyPadding: undefined,
  },
  setup(props, {slots}){

    let dropdownClasses = ref([])
    if(props.size){
      dropdownClasses.value.push(`dropdown-menu-${props.size}`)
    }
    if(props.direction){
      dropdownClasses.value.push(`dropdown-menu-${props.direction}`)
    }

    let hasBody   = slots.body && slots.body().length > 0
    let hasFooter = slots.footer && slots.footer().length > 0

    return {
      dropdownClasses,

      hasBody,
      hasFooter,
    }
  }
}
</script>
