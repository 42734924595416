<template>
  <li>
    <div class="toggle-wrap">
      <a href="javascript:;" class="btn btn-icon btn-trigger toggle" :class="{active: toggleActive}" v-on:click="changeToggleStatus">
        <nio-icon icon="ni-menu-right"></nio-icon>
      </a>
      <div class="toggle-content" :class="{'content-active': toggleActive}">
        <ul class="btn-toolbar gx-1">
          <li class="toggle-close">
            <a href="javascript:;" class="btn btn-icon btn-trigger toggle" v-on:click="changeToggleStatus">
              <nio-icon icon="ni-arrow-left"></nio-icon>
            </a>
          </li>
          <slot></slot>
        </ul>
      </div>
    </div>
  </li>
</template>

<script>
import NioIcon from "@core/components/nio-icon/NioIcon";
import {ref} from "vue";
export default {
  name: "BtnToolbarToggleWrap",
  components: {NioIcon},
  props:{
    id: {
      require: true,
      type: String,
    }
  },
  setup(){

    let toggleActive = ref(false)
    const changeToggleStatus = () => {
      toggleActive.value = !toggleActive.value
    }
    return {
      toggleActive,
      changeToggleStatus,
    }
  }
}
</script>
