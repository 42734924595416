<template>
  <li :class="{'btn-toolbar-sep' : separator !== undefined}">
    <slot></slot>
  </li>
</template>

<script>
export default {
  name: "BtnToolbarItem",
  props: {
    separator: null,
  }
}
</script>
