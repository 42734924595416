<template>
  <div class="custom-control custom-control-sm custom-checkbox" :class="{'notext' : !label.length}">
    <input type="checkbox" class="custom-control-input"
           :id="id"
           :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked)"
           :disabled="disabled">
    <label class="custom-control-label" :for="id"></label>
  </div>
</template>

<script>
import { defineComponent } from "vue"
export default defineComponent({
  props: {
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      required: true,
    },
    disabled: Boolean,
  },
})
</script>
